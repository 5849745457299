<template>
  <v-container>
    <v-row
    >
      <v-col lg="1" cols="12">
        <a
            href="/outils"
        ><v-img
            :src="require('../assets/Bouton retour.png')"
            contain
            max-height="34"
        /></a>
      </v-col>
    </v-row>
    <v-img
        :src="require('../assets/outils/calculateur-qt@2x.png')"
        contain
        max-height="150"
        min-width="600"
        offset-md="6"
    />
    <p></p>

    <v-row
        align="center"
        justify="space-around"
    >
      <v-btn @click="fruit"
          text
          color="orange"
      >
        Fruits
      </v-btn>
      <v-btn @click="fsecs"
             text
             color="orange"
      >
        Fruits secs
      </v-btn>
      <v-btn @click="cafe"
          text
          color="orange"
      >
        Café
      </v-btn>
      <v-btn @click="evenement"
          text
          color="orange"
      >
        Evénementiel
      </v-btn>
    </v-row>
    <v-row v-if="compFruit === true">
      <CalculatorFruit/>
    </v-row>
    <v-row v-if="compCafe === true">
      <CalculatorCafe/>
    </v-row>
    <v-row v-if="compEve === true">
      <CalculatorEve />
    </v-row>
    <v-row v-if="compFsecs === true">
      <CalculatorFsecs/>
    </v-row>
  </v-container>
</template>

<script>


import CalculatorCafe from "../components/Calculator/CalculatorCafe";
import CalculatorFsecs from "../components/Calculator/CalculatorFsecs";
import CalculatorEve from "../components/Calculator/CalculatorEve";
import CalculatorFruit from "../components/Calculator/CalculatorFruit";

export default {
  name: "Calculator",
  components: {
    CalculatorFruit,
    CalculatorEve,
    CalculatorCafe,
    CalculatorFsecs,
  },
  data(){
    return {
      compFruit: false,
      compCafe: false,
      compEve: false,
      compFsecs: false,
    }
  },

  methods: {
    fruit: function () {
      this.compFruit = true;
      this.compCafe = false;
      this.compEve = false;
      this.compFsecs = false;
    },
    cafe: function () {
      this.compCafe = true;
      this.compFruit = false;
      this.compEve = false;
      this.compFsecs = false;
    },
    evenement: function () {
      this.compEve = true;
      this.compCafe = false;
      this.compFruit = false;
      this.compFsecs = false;
    },
    fsecs: function () {
      this.compFsecs = true;
      this.compCafe = false;
      this.compEve = false;
      this.compFruit = false;
    },
  }
}
</script>

<style scoped>

</style>