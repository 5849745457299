<template>
  <v-container>
    <a href="https://docs.google.com/spreadsheets/d/1oJYDGS3bLt_yilMGUQV3MTdco1EZbguk4AmlQNZYbZQ/edit#gid=0" target="_blank">
      pop
    </a>
  </v-container>
</template>

<script>
export default {
  name: "CalculatorFruit",
  data: () => ({
    rules: [
      value => !!value || 'Required.',
    ],
    fruits:{},
    fruit:{
      raisinRouge: {
        name: "raisinRouge",
        prixKg: 0,
        poidsMoyen: 0,
        comp4kg:0,
        comp6kg:0,
        comp10kg:0,
      },
    },
    cagette: 0,
    consoMoy:10,
    poidsMoy:0.007,
    frequence: 4.33,
    poids: 0
  }),
  computed:{
    calculCafe(){
      return Math.round(this.collaborateur * this.consoMoy * this.poidsMoy * this.frequence)
    },
    equival250g(){
      return Math.round(this.calculCafe/0.25)
    },
    equival1Kg(){
      return this.calculCafe
    }
  }
}
</script>

<style scoped>

</style>