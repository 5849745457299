<template>
  <v-container>
    <v-card
        class="mx-auto"
        max-width="344"
    >
      <v-card-text>
        <div>J'aurai besoin de combien de</div>
        <p class="text-h4 text--primary">
          Café
        </p>
        <div>
          <v-text-field
              v-model="collaborateur"
              label="Nombre de collaborateurs"
              :rules="rules"
              hide-details="auto"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <p> Soit {{ equival250g }} de paquets de 250g</p>
      </v-card-actions>
      <v-card-actions>
        <p> Soit {{ equival1Kg }} de paquets de 1kg</p>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Calculator",
  data: () => ({
    rules: [
      value => !!value || 'Required.',
    ],
    collaborateur: 0,
    consoMoy:10,
    poidsMoy:0.007,
    frequence: 4.33,
    poids: 0
  }),
  computed:{
    calculCafe(){
      return Math.round(this.collaborateur * this.consoMoy * this.poidsMoy * this.frequence)
    },
    equival250g(){
      return Math.round(this.calculCafe/0.25)
    },
    equival1Kg(){
      return this.calculCafe
    }
  }
}
</script>

<style scoped>

</style>